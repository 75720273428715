/*!

=========================================================
* Argon Dashboard PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by www.creative-tim.com

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// Bootstrap RTL
@import 'bootstrap-rtl/bootstrap-rtl';

// Bootstrap functions
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/mixins';

// Argon functions
@import 'custom/functions';
@import 'custom/variables';
@import '~bootstrap/scss/variables';

// Argon mixins
@import 'custom/mixins';

// Bootstrap components
@import '~bootstrap/scss/root';
@import '~bootstrap/scss/reboot';
@import '~bootstrap/scss/type';
@import '~bootstrap/scss/images';
@import '~bootstrap/scss/code';
@import '~bootstrap/scss/grid';
@import '~bootstrap/scss/tables';
@import '~bootstrap/scss/forms';
@import '~bootstrap/scss/buttons';
@import '~bootstrap/scss/transitions';
@import '~bootstrap/scss/dropdown';
@import '~bootstrap/scss/button-group';
@import '~bootstrap/scss/input-group';
@import '~bootstrap/scss/custom-forms';
@import '~bootstrap/scss/nav';
@import '~bootstrap/scss/navbar';
@import '~bootstrap/scss/card';
@import '~bootstrap/scss/breadcrumb';
@import '~bootstrap/scss/pagination';
@import '~bootstrap/scss/badge';
@import '~bootstrap/scss/jumbotron';
@import '~bootstrap/scss/alert';
@import '~bootstrap/scss/progress';
@import '~bootstrap/scss/media';
@import '~bootstrap/scss/list-group';
@import '~bootstrap/scss/close';
@import '~bootstrap/scss/modal';
@import '~bootstrap/scss/tooltip';
@import '~bootstrap/scss/popover';
@import '~bootstrap/scss/carousel';
@import '~bootstrap/scss/utilities';
@import '~bootstrap/scss/spinners';
@import '~bootstrap/scss/print';

// Argon components
@import 'custom/components';
@import 'custom/utilities';
@import 'custom/vendors';

// React differences
@import 'react/react-differences';

@media (max-width: 765px) {
  input {
    font-size: 16px !important;
  }
}

.table th,
.table td {
  border-top: none;
}

.customer__info-list {
  list-style: none;
  background: #f6f6f6;
  padding: 8px;
  border-radius: calc(0.375rem - 1px);

  li {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 14px;
    padding: 4px;

    i {
      width: 32px;
    }
  }
}

.calendar-list {
  max-height: 300px;
  overflow-x: visible;
  overflow-y: auto;
  list-style: none;
  padding: 0;
  margin: 0 -8px;
}

.calendar-list-item {
  border-radius: calc(0.375rem - 1px);
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 14px;
  padding: 0;
  margin: 0;

  &:first-of-type {
    padding-top: 0;
  }

  &__date {
    width: 80px;
    font-weight: 700;

    span {
      display: inline-block;
      width: 40px;
      font-weight: 400;
    }
  }

  &__full-date {
    width: 90px;
    display: inline-block;
    font-weight: 400;
  }

  &__full-date + &__name {
    width: calc(100% - 90px);
  }

  &:nth-of-type(even) {
    background: #fcfcfc;
  }

  &__name {
    position: relative;
    display: flex;
    align-items: center;

    span {
      border-radius: 3px;
      width: 16px;
      height: 16px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 8px;
      text-align: cetner;
      color: #fff;
      font-weight: 700;
      background: #f75c03;
      font-size: 12px;
    }
  }
}

.calendar-list-item__actions {
  display: flex;
  margin-left: auto;
  justify-content: flex-end;

  button {
    border: none;
    outline: none;
    margin-left: 2px;
    background: transparent;
    color: $gray-600;
    padding: 0 8px;

    &:last-of-type {
      padding-right: 0;
    }

    &:hover,
    &:focus {
      color: $primary;
    }
  }
}

.calendar-actions_arrows {
  display: flex;
  align-items: center;

  div {
    width: 126px;
    cursor: pointer;
    margin: 0;
    text-align: center;

    &:hover,
    &:focus {
      color: $primary;
    }
  }

  button {
    border: none;
    outline: none;
    margin: 0;
    padding: 4px;
    background: none;

    &:first-of-type {
      margin-left: auto;
    }

    &:hover,
    &:focus {
      color: $primary;
    }
  }
}

.form-error__label {
  // position: absolute;
  display: block;
  color: #cc0000;
  font-size: 12px;
  padding: 4px 0 0 0;
  margin: 0 0 -12px 0;
}

.form-input--small {
  height: auto;
  font-size: 14px;
  padding: 2px 8px;
}

.form-table {
  thead,
  tfoot {
    th {
      padding: 8px !important;

      &:not(:first-of-type) {
        padding-right: 4px;
        padding-top: 4px;
      }
    }
  }

  tbody {
    td {
      padding: 0 3px !important;

      &:not(:first-of-type) {
        padding-right: 4px;
        padding-top: 4px;
      }
    }
  }
}

.form-group--table {
  margin-bottom: 0;

  input {
    height: auto;
    color: black;

    outline: 0px;
    background-color: white;
    margin: 0px 1px;
    padding: 5px 3px;
    border-radius: 4px;
    border: 1px solid transparent;
    font-size: 13px;

    transition: none;

    &:focus {
      border: 1px solid rgb(43, 143, 237);
      box-shadow: none;
      color: black;
    }
  }
}

.table--records {
  thead,
  tfoot {
    th {
      padding: 10px 10px !important;

      &:not(:first-of-type) {
        padding-right: 4px;
        padding-top: 4px;
      }
    }
  }
  // tfoot {
  //   th {
  //     border-top: none;
  //     border-bottom: none;
  //   }
  // }

  tbody {
    td {
      padding: 10px 10px !important;

      &:not(:first-of-type) {
        padding-right: 4px;
        padding-top: 4px;
      }
    }
  }
}

.app-scheduler-form {
  select {
    padding: 0 2px;
    height: 30px;
  }
}

// .printing-transactions {
//   background: white;

//   .sidenav,
//   .navbar-top,
//   .header-body nav,
//   .header-body a,
//   .card-stats p,
//   .card-footer,
//   .fa-print,
//   .hide-while-printing-transactions {
//     display: none !important;
//   }

//   a {
//     text-decoration: none;
//   }

//   .card {
//     box-shadow: none;
//     border: 2px solid #f9f9f9;
//   }

//   .main-content {
//     margin-left: 0 !important;
//   }

//   .footer {
//     display: none;
//   }
// }

.admin-layout-main .header + .container {
  min-height: calc(100vh - 210px);
}

.table-responsive {
  overflow-y: visible;
}

.milestones-form-table .table-responsive {
  max-height: 500px;
}

.app-checkbox {
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-bottom: 1.5rem;
  font-size: 14px;

  &:focus {
    .checkbox {
      border-color: #000;
    }
  }

  &--no-margin {
    margin-bottom: 0;
  }

  .app-checkbox__check {
    display: inline-flex;
    align-items: center;
    border: 1px solid #ccc;
    color: #5e72e4;
    font-size: 10px;
    height: 16px;
    width: 16px;
    min-height: 16px;
    min-width: 16px;
    justify-content: center;
    margin-right: 5px;
    padding: 1.5px;
  }
}

.notification__mark-as-complete {
  border-radius: 50%;
  width: 24px;
  height: 24px;
  border: none;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 10px;
  color: #fff;
  background-color: #adb5bd;

  &:hover,
  &:focus {
    color: #fff;
    background: #2dce89;
  }
}

.notifications-bell {
  border-radius: 50%;
  width: 21px;
  height: 21px;
  position: absolute;
  top: -6px;
  right: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 12px;
  font-weight: 700;
  background: #f02849;
}

.app-quill-content {
  border-radius: 4px;
  background: #f6f6f6;
  padding: 4px 8px;

  p {
    margin-bottom: 0;
  }
}

.card {
  border-radius: 1rem;
}

.card-header:first-child {
  border-radius: 1rem 1rem 0 0;
}

.card-footer:last-child {
  border-radius: 0 0 1rem 1rem;
}

.inventory-categories {
}

.inventory-categories-item {
  border: 1px solid rgba(0, 0, 0, 0.05);
  border-radius: 0.25rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 12px 8px;

  span {
    margin-left: 8px;
    font-size: 0.8125rem;
    font-weight: 600;
    color: #8898aa;
  }

  &:hover {
    border: 1px solid #ccc;
  }

  &--active {
    background: #5e72e4;

    span {
      color: #fff;
    }
  }

  .inventory-categories-item__img {
    width: 18px;
  }
}

.serials {
  gap: 8px;
  display: grid;

  &-form {
    gap: 16px;
    grid-template-columns: 1fr 64px;
    display: grid;
    align-items: center;

    .form-group {
      margin-bottom: 0;
    }

    .btn {
      margin: 0;
    }
  }
}

.serial {
  grid-template-columns: 1fr 50px;
  display: grid;

  &--active {
    color: #5e72e4;
    font-weight: 600;
  }
}

.equipment-selector {
  list-style: none;
  margin: 0;
  padding: 1.5rem 16px 1.5rem 16px;

  a {
    cursor: pointer;
    display: block;
    padding: 6px 8px;
    color: inherit;
    font-size: 14px;

    &:hover {
      background: #5e72e4;
      color: #fff;
    }
  }

  &-form {
    .form-group {
      margin-bottom: 0;
    }

    .btn {
      margin: 0;
    }
  }

  i {
    font-size: 14px;
    margin-right: 8px;
  }

  i.active {
    color: #2dce89;
  }
}

.projects-overview {
  &__title {
    color: #787878;
    font-size: 14px;
    font-weight: 600;
  }
}

.projects-overview-card {
  min-height: 250px;
  border-bottom-style: solid;
  border-bottom-width: 5px;

  &--blue {
    border-bottom-color: rgba(126, 192, 238, 0.2);
  }

  &--orange {
    border-bottom-color: rgba(250, 200, 152, 0.2);
  }

  &--yellow {
    border-bottom-color: rgba(253, 253, 150, 0.2);
  }

  &--purple {
    border-bottom-color: rgba(195, 177, 225, 0.2);
  }
}

.projects-overview-list {
  margin: 0;
  padding: 0;
  list-style: none;

  &__item {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 6px 0;

    font-size: 14px;
    font-weight: 400;
    color: #666;

    span {
      border-radius: 6px;
      min-width: 26px;
      margin-right: 8px;
      padding: 0 4px;
      text-align: center;
      font-size: 14px;
      font-weight: 600;
      color: #353535;
      background: rgba(200, 200, 200, 0.05);
    }

    &--active {
      color: #5e72e4;
    }

    &--empty {
      opacity: 0.3;
    }

    &--green {
      span {
        background: rgba(#c1e1c1, 0.5);
      }
    }

    // &--yellow {
    //   span {
    //     background: rgba(255, 157, 0, 0.1);
    //   }
    // }

    // &--red {
    //   span {
    //     background: rgba(225, 0, 0, 0.1);
    //   }
    // }

    // &--green {
    //   span {
    //     background: rgba(0, 225, 25, 0.1);
    //   }
    // }
  }
}

.submission-sidebar {
  &__badge {
    border-radius: 4px;
    padding: 2px 8px;
    font-weight: 700;
    font-size: 10px;
    color: #000000;
    background: #f6bf15;
  }

  &__title {
    margin: 8px 0 0 0;
    font-weight: 900;
    font-size: 20px;
  }

  &__subtitle {
    margin: 0;
    font-size: 14px;
  }
}

.submission-steps {
  list-style: none;
  margin: 16px 0 0 0;
  padding: 0;

  li {
    a {
      display: block;
      padding: 8px 0 8px 16px;
      font-size: 16px;

      &.active {
        color: #344767;
        position: relative;

        &:before {
          position: absolute;
          left: 0;
          font-family: 'Font Awesome 5 Free';
          font-weight: 700;
          color: rgba($color: #344767, $alpha: 0.8);
          content: '\f0da';
        }
      }

      &.completed {
        color: #299617;
      }

      &.next {
        font-weight: 400;
        color: rgba($color: #344767, $alpha: 0.3);
      }
    }
  }
}

@keyframes question-animate {
  50% {
    color: #f6bf15;
  }
}

@keyframes hidden-section-animate {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.submission-form {
  &__question {
    font-size: 14px;
    font-weight: 700;
    animation: question-animate 0.3s linear 2;
    color: $primary;
  }

  &__hidden-section {
    border-radius: 4px;
    margin-bottom: 8px;
    padding: 8px 8px 0;
    background: #f9f9f9;
    animation: hidden-section-animate 0.3s linear;
  }
}

.submission-products {
  display: grid;
  gap: 8px;

  &__search {
    margin: 16px 0 -16px 0;
  }
}

.submission-products-item {
  border-radius: 4px;
  width: 100%;
  padding: 4px 8px;
  display: grid;
  grid-template-columns: 1fr 85px 40px;
  align-items: center;
  background: rgba($color: #5e72e4, $alpha: 0.1);

  &__name {
    font-size: 14px;
  }

  .form-group {
    margin: 0;

    input {
      padding-right: 4px;
      padding-left: 4px;
      text-align: right;
    }
  }

  &__delete {
    padding: 2px 8px;
    text-align: right;
    color: inherit;
    font-size: 14px;
  }
}

.app-search {
  position: relative;
  margin-bottom: 32px;
}

.app-search-results {
  border-radius: 0.4375rem;
  border: 1px solid #f3f3f3;
  position: absolute;
  width: 100%;
  max-height: 150px;
  top: 35px;
  left: 0;
  z-index: 1000;
  list-style: none;
  margin: 0;
  padding: 0;
  overflow-y: auto;
  background: white;
  animation: hidden-section-animate 0.3s linear;

  &::-webkit-scrollbar {
    width: 10px;
  }

  &::-webkit-scrollbar-track {
    border-radius: 100px;
    background-color: #e4e4e4;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 100px;
    background-color: $primary;
  }

  li {
    a {
      display: block;
      padding: 0.5rem 1rem;
      font-size: 14px;

      &:hover {
        background: #fcfcfc;
      }
    }
  }
}

.btn {
  box-shadow: none;
}

.submission {
  grid-template-columns: 3fr 2fr;
  gap: 20px 40px;
  position: relative;
  z-index: 100;
  display: grid;
  margin-top: -60px;

  .card {
    margin-bottom: 0;
  }
}

.submission-activities {
  &-header {
    align-items: center;
    display: flex;

    span {
      font-weight: 700;
      font-size: 16px;
    }

    a {
      display: flex;
      align-items: center;
      margin-left: auto;
      font-weight: 600;
      font-size: 14px;

      i {
        margin-left: 6px;
        font-size: 10px;
      }
    }
  }

  &-container {
    border-radius: 1rem;
    box-shadow: 0 0 2rem 0 rgb(136 152 170 / 15%);
    gap: 10px;
    display: grid;
    margin-top: 10px;
    padding: 20px;
    word-wrap: break-word;
    background-color: #fff;
  }
}

.submission-activity {
  &:hover {
    background: rgba(17, 91, 168, 0.02);
  }

  &-container {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  &__date {
    line-height: 12px;
    font-size: 12px;
    padding: 4px 0;

    &:hover {
      span {
        opacity: 1;
      }
    }

    span {
      opacity: 0;
      transition: opacity 150ms ease-out;
    }
  }

  &__name {
    margin-right: auto;
    font-weight: 600;
    font-size: 14px;
    line-height: 14px;
  }

  &__action {
    border-radius: 6px;
    width: 28px;
    height: 28px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 4px;
    color: #6c6c6c;
    font-size: 12px;
    transition: all 200ms ease-in;

    &:hover,
    &:focus {
      color: #115ba8;
      background: #f4f4f4;
    }
  }
}

.submission-components {
  margin-top: 20px;

  &-header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &__title {
      display: flex;
      align-items: center;
      font-weight: 700;
      font-size: 16px;
      min-width: 100px;

      &:hover {
        a {
          display: block;
        }
      }

      a {
        display: none;
        font-weight: 600;
        font-size: 14px;

        i {
          margin-left: 10px;
          font-size: 12px;
        }
      }
    }
  }

  &-container {
    border-radius: 1rem;
    box-shadow: 0 0 2rem 0 rgb(136 152 170 / 15%);
    gap: 10px;
    display: grid;
    margin-top: 10px;
    padding: 20px;
    word-wrap: break-word;
    background-color: #fff;
  }
}

.submission-components-summary {
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 14px;
  font-variant-numeric: tabular-nums;

  img {
    height: 14px;
    margin: 0 4px 0 10px;
  }

  small {
    margin-left: 4px;
    font-size: 12px;
  }
}

.submission-component {
  grid-template-columns: 1fr auto auto;
  display: grid;
  flex-direction: row;
  align-items: center;

  &:hover {
    background: rgba(17, 91, 168, 0.02);
  }

  &__date {
    line-height: 12px;
    font-size: 12px;
    padding: 4px 0;

    &:hover {
      span {
        opacity: 1;
      }
    }

    span {
      opacity: 0;
      transition: opacity 150ms ease-out;
    }
  }

  &__name {
    margin-right: auto;
    font-weight: 600;
    font-size: 14px;
    line-height: 14px;
  }

  &__capacity {
    grid-template-columns: 70px 40px 70px;
    display: grid;
    align-items: center;
    text-align: right;
    color: #6c6c6c;

    i {
      font-size: 12px;
      color: #ccc;
    }
  }
}

.submission-documents {
  &-header {
    align-items: center;
    display: flex;

    span {
      font-weight: 700;
      font-size: 16px;
    }

    a {
      display: flex;
      align-items: center;
      margin-left: auto;
      font-weight: 600;
      font-size: 14px;

      i {
        margin-left: 6px;
        font-size: 10px;
      }
    }
  }

  &-container {
    border-radius: 1rem;
    box-shadow: 0 0 2rem 0 rgb(136 152 170 / 15%);
    gap: 5px;
    display: grid;
    margin-top: 10px;
    padding: 20px;
    word-wrap: break-word;
    background-color: #fff;
  }

  &__heading {
    display: flex;
    align-items: center;
    font-weight: 600;
    font-size: 14px;

    span {
      margin-right: auto;
    }

    a {
      margin-left: 8px;
      font-weight: 400;
      font-size: 14px;
    }

    &--mt {
      padding-top: 20px;
    }
  }

  &-actions {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-top: 20px;
  }

  &__action {
    border: none;
    width: fit-content;
    border-radius: 6px;
    display: inline-block;
    margin-left: 8px;
    padding: 8px 10px;
    line-height: 14px;
    font-weight: 600;
    font-size: 14px;
    color: #ffffff;
    background: #115ba8;

    &:hover,
    &:focus {
      color: #333333;
      background: #f6b11c;
    }
  }
}

.submission-document {
  grid-template-columns: 1fr auto;
  gap: 10px;
  min-height: 28px;
  display: grid;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;

  .submission-document__name {
    width: 100%;
    padding: 4px 0;
  }

  &:hover {
    .submission-document-container,
    .submission-document__name {
      background: rgba(17, 91, 168, 0.02);
    }

    .submission-document__action {
      display: flex;
    }
  }

  &__name {
    margin-right: auto;
    font-size: 14px;
    line-height: 14px;
  }

  &__action {
    border-radius: 6px;
    width: 28px;
    height: 28px;
    display: none;
    justify-content: center;
    align-items: center;
    margin-left: 4px;
    color: #6c6c6c;
    font-size: 12px;
    transition: all 200ms ease-in;

    &:hover,
    &:focus {
      color: #115ba8;
      background: #f4f4f4;
    }
  }

  &__edit {
    border-radius: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    // text-decoration: underline;
    padding: 4px 4px;
    color: #6c6c6c;
    font-size: 14px;
    transition: all 200ms ease-in;

    &:hover,
    &:focus {
      // text-decoration: underline;
      color: #115ba8;
      background: #f4f4f4;
    }
  }
}

.submission-log {
  grid-column: 1 / 3;
}

.project-label {
  border: 1px solid #000;
  border-radius: 20px;
  padding: 4px 10px;
  font-weight: 600;
  font-size: 12px;

  &--blue {
    border-color: #7ec0ee;
    color: #4c4c4c;
    background: rgba(126, 192, 238, 0.2);
  }

  &--orange {
    border-color: #fac898;
    color: #4c4c4c;
    background: rgba(250, 200, 152, 0.2);
  }

  &--yellow {
    border-color: #fffaa0;
    color: #4c4c4c;
    background: rgba(253, 253, 150, 0.2);
  }

  &--purple {
    border-color: #c3b1e1;
    color: #4c4c4c;
    background: rgba(195, 177, 225, 0.2);
  }

  &--green {
    border-color: #c1e1c1;
    color: #4c4c4c;
    background: rgba(193, 225, 193, 0.2);
  }
}

.canvas-image-box {
  background-color: #fafafa;
  height: 64px;
  width: 300px;
  border-style: solid;
  border-width: 1px;
  border-color: #eaeaea;
  overflow: hidden;
}

.canvas-seal-image-box {
  background-color: #fafafa;
  height: 230px;
  width: 230px;
  border-style: solid;
  border-width: 1px;
  border-color: #eaeaea;
  overflow: hidden;
}
